
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/notice/message/BasicUsage.vue";
import EUITypes from "@/views/resources/documentation/element-ui/notice/message/Types.vue";
import EUIClosable from "@/views/resources/documentation/element-ui/notice/message/Closable.vue";
import EUICenteredText from "@/views/resources/documentation/element-ui/notice/message/CenteredText.vue";
import EUIUseHTMLString from "@/views/resources/documentation/element-ui/notice/message/UseHTMLString.vue";

export default defineComponent({
  name: "message",
  components: {
    EUIBasicUsage,
    EUITypes,
    EUIClosable,
    EUICenteredText,
    EUIUseHTMLString
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Message");
    });
  }
});
